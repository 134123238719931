import React, { useEffect } from "react"
import {
  FaUser,
  FaEnvelope,
  FaExclamationTriangle,
  FaBusinessTime,
} from "react-icons/fa"
import useForm from "./useForm"
import { navigate } from "gatsby-link"

const lenguaje = {
  en: ["Name", "Company", "Email", "Message", "Send", "Cancel"],
  sp: ["Nombre", "Empresa", "Email", "Mensaje", "Enviar", "Cancelar"],
}

const idioma = lenguaje.en

export default function ContactForm({ onClickModal }) {
  const { state, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  )

  useEffect(() => {
    const cerrar = () => onClickModal()
    return () => {
      cerrar()
    }
  }, [onClickModal])

  return (
    <form
      name="contact"
      method="post"
      action="/gracias"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleOnSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="field">
        <label className="label has-text-white">{idioma[0]}</label>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            name="name"
            id="name"
            value={state.name.value}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <FaUser className="fas fa-user"></FaUser>
          </span>
        </div>
        {state.name.error && (
          <p className="help is-danger">{state.name.error}</p>
        )}
      </div>

      <div className="field">
        <label className="label has-text-white">{idioma[1]}</label>
        <div className="control has-icons-left has-icons-right">
          <input
            className="input is-success"
            type="text"
            id="empresa"
            name="empresa"
            value={state.empresa.value}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <FaBusinessTime className="fas fa-user"></FaBusinessTime>
          </span>
        </div>
        {state.empresa.error && (
          <p className="help is-danger">{state.empresa.error}</p>
        )}
      </div>

      <div className="field">
        <label className="label has-text-white">{idioma[2]}</label>
        <div className="control has-icons-left has-icons-right">
          <input
            className="input is-danger"
            type="email"
            id="email"
            name="email"
            value={state.email.value}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <FaEnvelope className="fas fa-envelope"></FaEnvelope>
          </span>
          <span className="icon is-small is-right is-hidden">
            <FaExclamationTriangle className="fas fa-exclamation-triangle"></FaExclamationTriangle>
          </span>
        </div>
        {state.email.error && (
          <p className="help is-danger">{state.email.error}</p>
        )}
      </div>

      {/*
      <div className="field">
        <label className="label has-text-white">Tamaño de empresa</label>
        <div className="control">
          <div className="select">
            <select>
              <option>Seleccione</option>
              <option>1 a 9 trabajadores</option>
              <option>10 a 49 trabajadores</option>
              <option>50 a 199 trabajadores</option>
              <option>200 o más trabajadores</option>
            </select>
          </div>
        </div>
      </div> */}

      <div className="field">
        <label className="label has-text-white">{idioma[3]}</label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder=""
            id="mensaje"
            name="mensaje"
            value={state.mensaje.value}
            onChange={handleOnChange}
          ></textarea>
        </div>
        {state.mensaje.error && (
          <p className="help is-danger">{state.mensaje.error}</p>
        )}
      </div>

      {/* <div className="field">
        <div className="control">
          <label className="checkbox">
            <input type="checkbox" /> I agree to the{" "}
            <a href="#">terms and conditions</a>
          </label>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="radio">
            <input type="radio" name="question" />
            Yes
          </label>
          <label className="radio">
            <input type="radio" name="question" />
            No
          </label>
        </div>
      </div> */}

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary is-outlined" type="submit">
            {idioma[4]}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-danger is-outlined"
            onClick={onClickModal}
          >
            {idioma[5]}
          </button>
        </div>
      </div>
    </form>
  )
}

// Define your state schema
const stateSchema = {
  botField: { value: "", error: "" },
  name: { value: "", error: "" },
  empresa: { value: "", error: "" },
  email: { value: "", error: "" },
  mensaje: { value: "", error: "" },
}

// Define your validationStateSchema
// Note: validationStateSchema and stateSchema property
// should be the same in-order validation works!
const validationStateSchema = {
  name: {
    required: true,
    validator: {
      regEx: /^[a-zA-Z ]+$/,
      error: "Ingrese un nombre válido.",
    },
  },
  empresa: {
    required: true,
    validator: {
      regEx: /(.|\n)*/,
      error: "Indique el nombre de su empresa.",
    },
  },
  email: {
    required: true,
    validator: {
      regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error: "Ingrese un email válido.",
    },
  },
  mensaje: {
    required: true,
    validator: {
      regEx: /(.|\n)*/,
      error: "Ingrese un mensaje.",
    },
  },
  botField: {
    required: false,
    validator: {
      regEx: /^$/,
      error: "Eres un bot",
    },
  },
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const onSubmitForm = (state, event) => {
  const form = event.target
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": form.getAttribute("name"),
      "bot-field": state.botField.value,
      name: state.name.value,
      empresa: state.empresa.value,
      email: state.email.value,
      mensaje: state.mensaje.value,
    }),
  })
    .then(() => navigate(form.getAttribute("action")))
    .catch(error => alert(error))
}
