import React, { useEffect } from "react"
import anime from "animejs"

// For Gatsby, ou should install emotion-plugin for margins
const Loader = () => {
  useEffect(() => {
    anime({
      targets: `#pathl1`,
      loop: true,
      rotate: [
        {
          value: `2turn`,
          duration: 2000,
          easing: `easeInOutSine`,
        },
      ],
      strokeWidth: [
        { value: 15, duration: 1000 },
        { value: 15, duration: 1000 },
      ],
      scale: [
        { value: 0.2, duration: 1 },
        { value: 0.2, duration: 1999 },
      ],
      opacity: [
        { value: 1, duration: 1000 },
        { value: 1, duration: 1000 },
      ],
    })
  }, [])

  return (
    <div className="main-logo" css={{ background: "#173030" }}>
      <div className="logo-movible">
        <div className="divLogo">
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: `relative`,
              width: `100%`,
              top: `0`,
              left: `0`,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 108 123"
              width="80%"
              version="1.1"
              id="sv3"
              css={{ maxHeight: `100vh` }}
            >
              <path
                id="pathl1"
                className="cls-logo"
                d="M 0,45 H 23 L 23,50 H 0 Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
