import React, { useState } from "react"
import SEO from "../components/seo"
import "../components/styles/mystyles.scss"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import LogoAT from "../components/loaderAT"
import Logo from "../components/logo"

const CanvasR3F = Loadable(() => import("../components/canvasr3f"), {
  ssr: false,
})
const ContactModal = Loadable(() => import("../components/contact-modal"))

const Simulation = () => {
  const [showModal, setShowModal] = useState(false)
  const onClickModal = () => {
    setShowModal(!showModal)
  }
  const [finished, setFinished] = useState(null)
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const handleAnimationCompleted = (e) => {
    setAnimationCompleted(e)
  }

  CanvasR3F.load().then(() => setFinished(true))

  return (
    <Layout
      animationCompleted={animationCompleted}
      cbLogo={handleAnimationCompleted}
      onClickModal={onClickModal}
      finished={finished}
    >
      <SEO
        title="Mine Truck"
        keywords={[`antofatech`, `informatica`, `mineria`, `innovacion`]}
      />
      <div className="bg" css={{ zIndex: 0 }}>
        <CanvasR3F fallback={<LogoAT />} url={"/797F_compress.glb"} />
      </div>
      {showModal && (
        <ContactModal showModal={showModal} onClickModal={onClickModal} />
      )}
    </Layout>
  )
}

export default Simulation
