import React from "react"
import "chartjs-plugin-streaming"
import { Line } from "react-chartjs-2"

function randomScalingFactor() {
  return (Math.random() > 0.5 ? 1.0 : -1.0) * Math.round(Math.random() * 100)
}
function onRefresh(chart) {
  chart.data.datasets.forEach(function(dataset) {
    dataset.data.push({
      x: Date.now(),
      y: randomScalingFactor(),
    })
  })
}
const LineChartHS = props => {
  const dataLine = {
    datasets: [
      {
        label: "Dataset 1",
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        lineTension: 0,
        fill: true,
        borderDash: [8, 4],
        data: [],
      },
      {
        label: "Dataset 2",
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        fill: false,
        cubicInterpolationMode: "monotone",
        data: [],
      },
    ],
  }
  const optionsLine = {
    scales: {
      xAxes: [
        {
          type: "time",
          realtime: {
            duration: 10000,
            refresh: 1000,
            delay: 2000,
            onRefresh: onRefresh,
          },
          time: {
            minUnit: "second",
          },
          ticks: {
            display: true,
            fontColor: props.colorLetras,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          scaleLabel: {
            display: true,
            labelString: "value",
          },
          ticks: {
            fontColor: props.colorLetras,
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      streaming: {
        frameRate: 30,
      },
    },
  }
  return <Line data={dataLine} options={optionsLine} />
}

export default LineChartHS
