import React, { useState } from "react"
import * as d3 from "d3"
import PieHooks from "./pie_hooks"
import PieSVG from "./pie_svg"

function PlotD3() {
  const generateData = (value, length = 5) =>
    d3.range(length).map((item, index) => ({
      date: index,
      value:
        value === null || value === undefined ? Math.random() * 100 : value,
    }))

  const [data1, setData1] = useState(generateData())
  const [data2, setData2] = useState(generateData())
  const changeData = () => {
    setData1(generateData())
    setData2(generateData())
  }

  return (
    <div className="columns is-mobile">
      <div
        className="column has-text-centered"
        css={{
          background: "rgba(0,0,0,0.1)",
        }}
      >
        <span className="label has-text-white has-text-left">Producción</span>
        <PieHooks
          data={data1}
          width={200}
          height={200}
          innerRadius={60}
          outerRadius={100}
        />
      </div>
      <div
        className="column has-text-centered"
        css={{
          background: "rgba(0,0,0,0.1)",
        }}
      >
        <span className="label has-text-white has-text-left">Operación</span>
        <PieSVG
          data={data2}
          width={200}
          height={200}
          innerRadius={60}
          outerRadius={100}
        />
      </div>
      <div className="column is-2">
        <button
          className="button is-success"
          onClick={changeData}
          style={{ minWidth: 50, width: "100%", maxWidth: 100 }}
        >
          Click
        </button>
      </div>
    </div>
  )
}

export default PlotD3
