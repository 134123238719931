import React, { useEffect } from "react"
import anime from "animejs"
import { FaChevronUp, FaChevronDown } from "react-icons/fa"

const Flecha = () => {
  useEffect(() => {
    anime({
      targets: `#tick-demo-down`,
      translateY: [20, -20],
      direction: `reverse`,
      opacity: [0.2, 0],
      loop: true,
      easing: `easeInOutQuad`,
      autoplay: true,
    })
    anime({
      targets: `#tick-demo-up`,
      translateY: [20, -20],
      opacity: [0, 0.2],
      loop: true,
      easing: `easeInOutQuad`,
      autoplay: true,
    })
    return () => anime.remove(`#tick-demo-down #tick-demo-down`)
  })

  return (
    <div>
      <figure className="has-text-centered">
        <span className="is-clearfix">
          <FaChevronDown
            id="tick-demo-down"
            className="has-text-white has-text-weight-bold is-size-1 is-hidden-mobile"
            css={{ opacity: 0 }}
          />
          <FaChevronUp
            id="tick-demo-up"
            className="has-text-white has-text-weight-bold is-size-3 is-hidden-tablet"
            css={{ opacity: 0 }}
          />
        </span>
      </figure>
    </div>
  )
}

export default Flecha
