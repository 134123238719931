/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "../components/logo"
import LogoAT from "../components/loaderAT"
import anime from "animejs"
import "../components/styles/mystyles.scss"
import Header from "./header"

const Layout = ({ children, cbLogo, onLoad, finished, pag1, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [logo, setLogo] = useState(true)

  const LogoFinal = useMemo(() => {
    if (cbLogo) {
      return (
        <>
          {logo ? (
            <>
              {!finished ? (
                <LogoAT />
              ) : (
                <Logo cbLogo={cbLogo} animar={logo} pag1={pag1} />
              )}
            </>
          ) : (
            <Animacion2 />
          )}
        </>
      )
    } else {
      return <Animacion1 />
    }
  }, [cbLogo, finished, logo, pag1])

  return (
    <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} {...props} />
      <div
        className="inicio"
        css={{
          margin: `0 auto`,
        }}
      >
        {LogoFinal}
        <main className="base">{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Animacion1 = () => {
  useEffect(() => {
    anime({
      targets: `.base`,
      duration: 3000,
      translateX: ["-100vw", 0],
    })
  }, [])
  return <div className="main-logo-bg" css={{ zIndex: -1 }} />
}

const Animacion2 = () => {
  useEffect(() => {
    anime({
      targets: `.main-logo-bg`,
      duration: 3000,
      opacity: 0,
      easing: `linear`,
    })
  }, [])
  return <div className="main-logo-bg" css={{ zIndex: -2 }} />
}
