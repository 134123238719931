import React, { useMemo } from "react"
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader"
import { LinearFilter, sRGBEncoding } from "three/src/constants"

import img1 from "../components/threejs/assets/px.hdr.svg"
import img2 from "../components/threejs/assets/nx.hdr.svg"
import img3 from "../components/threejs/assets/py.hdr.svg"
import img4 from "../components/threejs/assets/ny.hdr.svg"
import img5 from "../components/threejs/assets/pz.hdr.svg"
import img6 from "../components/threejs/assets/nz.hdr.svg"

const urls = [img1, img2, img3, img4, img5, img6]

export const CubeMap = () =>
  new HDRCubeTextureLoader().load(urls, cubeMap => {
    cubeMap.magFilter = LinearFilter
    cubeMap.needsUpdate = true
  })
