import React from "react"
import Copyright from "./copyright"

export default function Footer() {
  return (
    <footer
      className="footer is-clearfix"
      css={{
        background: "rgba(255,255,255,0)",
        backgroundImage: "url('/bg_curve_verde2.svg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        alignItems: "flex-end",
        justifyContent: "center",
        padding: "24px 48px 20px",
        display: "flex",
        position: "relative",
        height: "30vh",
        bottom: 0,
        zIndex: 3,
        marginBottom: 0,
      }}
    >
      <Copyright />
    </footer>
  )
}
