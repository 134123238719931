import React, { useMemo, useCallback } from "react"
import { DoubleSide } from "three/src/constants"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { Color } from "three/src/math/Color"
import { MeshBasicMaterial } from "three/src/materials/MeshBasicMaterial"
import { AdditiveBlending } from "three/src/constants"
import { CubeMap } from "./cubeMap"
import { useLoader } from "@react-three/fiber"

export default function ModelR3F({
  url,
  segunda,
  color,
  active,
  camion,
  ...props
}) {
  const model = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("/draco-gltf/")
    dracoLoader.preload()
    loader.setDRACOLoader(dracoLoader)
  })

  const newMaterial = useCallback((color) => {
    const newMaterial = new MeshBasicMaterial({
      color: new Color(color),
    })
    return newMaterial
  }, [])

  const gltf = useMemo(
    () => (segunda ? model.scene : model.scene.clone(true)),
    [model.scene, segunda]
  )

  const cubeMap = useMemo(() => CubeMap(), [])

  const render1 = useCallback(() => {
    gltf.traverse((child) => {
      if (child.material) {
        if (camion === "tren") {
          child.visible = false
          if (child.name === "mando_final_izquierdo") {
            child.visible = true
          }
          if (child.name === "mando_final_derecho") {
            child.visible = true
          }
          if (child.name === "transmision") {
            child.visible = true
          }
          if (child.name === "union_de_mandos") {
            child.visible = true
          }
          if (child.name === "motor") {
            child.visible = true
          }
        } else if (camion === "tolva") {
          child.visible = false
          if (child.name === "tolva") {
            child.visible = true
          }
        } else {
          if (child.name === "rueda_delantera_der") {
            child.visible = true
            child.material.metalness = 0
            child.material.roughness = 0
          } else {
            child.visible = true
            child.material.metalness = 1
            child.material.roughness = 0.4
          }
        }
        if (child.name === `Cab_Glass`) {
          child.material.opacity = 0.4
          child.material.transparent = true
          child.material.transmission = 1
          child.material.premultipliedAlpha = true
          child.material.metalness = 0
          child.material.roughness = 0
        }
        if (child.name.match(/^OilTank0\d{2}(\d{3})?$/)) {
          child.material.emissive = new Color(0xff0000)
        }
        if (child.name === `Object413`) {
          child.material.emissive = new Color(0xffff00)
          child.material.emissiveIntensity = 1
          child.material.opacity = 0.5
          child.material.transparent = true
          child.material.transmission = 1
          child.material.premultipliedAlpha = true
        }
        if (child.name === `Motor`) {
          child.material.metalness = 0
          child.material.roughness = 0
        }
        if (child.name === `Deck_Walls`) {
          child.material.metalness = 0.4
          child.material.roughness = 0.6
        }
        if (child.name === `Counter_Weight`) {
          child.material.metalness = 1
          child.material.roughness = 1
        }
        if (child.name === `Exhaust_Base`) {
          child.material.metalness = 0
          child.material.roughness = 0
        }
        if (child.name === `Deck_Frame`) {
          child.material.metalness = 0.5
          child.material.roughness = 0.5
        }
        if (child.name === `Boom`) {
          child.material.metalness = 1
          child.material.roughness = 1
        }
        if (child.name === `Dipper`) {
          child.material.metalness = 1
          child.material.roughness = 1
          // child.material.visible = false
        }
        if (child.name === `Tracks`) {
          child.material.metalness = 1
          child.material.roughness = 1
        }
        if (child.name === `Dipper_Handle`) {
          child.material.metalness = 0.6
          child.material.roughness = 1
        }
        if (child.name === `Exhaust_Caps`) {
          child.material.metalness = 0
          child.material.roughness = 0
        }
        if (child.name.match(/^Crawler_Frame_*/)) {
          child.material.metalness = 0.5
          child.material.roughness = 0.5
        }
        if (child.name.match(/^Handrails.*/)) {
          child.material.metalness = 0
          child.material.roughness = 1
        }
        if (child.name === `Base`) {
          child.material.metalness = 0
          child.material.roughness = 1
        }
        if (child.name === `Cab001`) {
          child.material.emissive = new Color(0x212121)
          child.material.emissiveIntensity = 1.7 //0.7
        }
        child.castShadow = true
        child.receiveShadow = true
        child.material.envMapIntensity = 2
        child.material.envMap = cubeMap
        child.material.side = DoubleSide
        child.material.alphaTexture = true
      }
    })
  }, [gltf, camion])

  const render2 = useCallback(() => {
    gltf.traverse((child) => {
      if (child.isMesh) {
        child.material = newMaterial(color)
        child.material.wireframe = active ? true : false
        child.material.blending = AdditiveBlending
        if (child.name === "Cube") {
          child.visible = false
        }
      }
    })
  }, [active, color, gltf, newMaterial])

  useMemo(() => {
    segunda ? render1() : render2()
  }, [camion, segunda])

  return gltf ? <primitive object={gltf} {...props} /> : null
}
