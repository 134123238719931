import React, { useState } from "react"
import SEO from "../components/seo"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import Footer from "../components/footer"
import { Global, css } from "@emotion/react"

const ContactModal = Loadable(() => import("../components/contact-modal"))

const NotFoundPage = () => {
  const [showModal, setShowModal] = useState(false)

  const onClickModal = () => {
    setShowModal(!showModal)
  }

  return (
    <Layout animationCompleted={true} onClickModal={onClickModal}>
      <SEO title="404: Not found" />
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
        `}
      />
      <section className="hero is-fullheight is-dark is-medium">
        <div className="hero-head" />"
        <div
          className="hero-body"
          css={{ display: "flex", alignItems: "center" }}
        >
          <div className="container">
            <h1 className="title">No Encontrado</h1>
            <h2 className="subtitle">Esta página no existe.</h2>
            <br />
            <br />
            <h1 className="title">Not Found</h1>
            <h2 className="subtitle">This page doesn&#39;t exist.</h2>
          </div>
        </div>
        <div className="hero-foot">
          <Footer />
        </div>
      </section>
      {showModal && (
        <ContactModal showModal={showModal} onClickModal={onClickModal} />
      )}
    </Layout>
  )
}

export default NotFoundPage
