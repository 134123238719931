import React, { useMemo, useState, useRef, useEffect, useCallback } from "react"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { Color } from "three/src/math/Color"
import { MeshBasicMaterial } from "three/src/materials/MeshBasicMaterial"
import { AdditiveBlending, LoopPingPong } from "three/src/constants"
import { AnimationMixer } from "three/src/animation/AnimationMixer"
import { useLoader, useFrame } from "@react-three/fiber"

export default function ModeloAT({ url, color, active, y, ...props }) {
  const gltf = useLoader(GLTFLoader, url, loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("/draco-gltf/")
    dracoLoader.preload()
    loader.setDRACOLoader(dracoLoader)
  })

  const newMaterial = useCallback(color => {
    const newMaterial = new MeshBasicMaterial({
      color: new Color(color),
    })
    return newMaterial
  }, [])

  useMemo(
    () =>
      gltf.scene.traverse(child => {
        if (child.isMesh) {
          child.material = newMaterial(color)
          child.material.wireframe = active ? true : false
          child.material.blending = AdditiveBlending
          if (child.name === "Cube") {
            child.visible = false
          }
        }
      }),
    [active, color, gltf.scene, newMaterial]
  )

  const group = useRef()
  const [mixer] = useState(() => new AnimationMixer())
  useFrame((state, delta) => active && mixer.update(delta * 0.5))
  useEffect(() => {
    if (gltf) {
      void gltf.animations.forEach(clip => {
        mixer
          .clipAction(clip, group.current)
          .setLoop(LoopPingPong, 1)
          .play()
      })
    }
    return () =>
      gltf && gltf.animations.forEach(clip => mixer.uncacheClip(clip))
  }, [gltf, mixer])

  return gltf ? (
    <group ref={group}>
      <primitive object={gltf.scene} {...props} />
    </group>
  ) : null
}
