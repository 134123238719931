import React, { useState } from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import TopBarProgress from "react-topbar-progress-indicator"

const lenguaje = {
  en: ["Home", "Simulation", "Contact Us"],
  sp: ["Inicio", "Simulación", "Contáctanos"],
}

const idioma = lenguaje.en

export default function Navbar({ animationCompleted, onClickModal }) {
  const [toggleMenu, setToggleMenu] = useState(false)
  const onClickHandle = () => {
    onClickModal()
    setToggleMenu(false)
  }
  TopBarProgress.config({
    barColors: {
      "0": "#fff",
      "1.0": "#fff",
    },
    shadowBlur: 5,
  })

  return (
    <>
      <nav
        className={`navbar is-fixed-top is-transparent ${!animationCompleted &&
          "is-invisible"} ${toggleMenu ? "is-dark" : ""}`}
        role="navigation"
        aria-label="main navigation"
        css={{
          backgroundColor: "transparent",
          background: "rgba(0,0,0,0.3)",
        }}
      >
        <div className="navbar-brand">
          <a
            className="navbar-item is-hidden-mobile"
            href="https://antofa.tech"
          >
            <img src="/anto_letras.svg" alt="Antofatech Spa Logo" />
          </a>
          <a
            className="navbar-item is-hidden-tablet"
            href="https://antofa.tech"
          >
            <img src="/anto_logo.svg" alt="Antofatech Spa Logo" />
          </a>

          <a
            role="button"
            className={`navbar-burger burger has-text-white ${
              toggleMenu ? "is-active" : ""
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasic"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          id="navbarBasic"
          className={`navbar-menu ${toggleMenu ? "is-active" : "is-dark"}`}
          css={css`
            background: ${toggleMenu ? "#363636" : ""};
          `}
        >
          <div
            className="navbar-start"
            css={{ flexGrow: "1", justifyContent: "center" }}
          >
            <Link
              to="/"
              className="navbar-item has-text-light"
              onClick={() => setToggleMenu(false)}
            >
              {idioma[0]}
            </Link>
            {/* <Link to="/empresas" className="navbar-item has-text-light">
              Empresas
            </Link> */}
            <Link to="/simulation" className="navbar-item has-text-light">
              {idioma[1]}
            </Link>
            {/* <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link has-text-light">Más</a>
              <div
                className="navbar-dropdown"
                css={{ background: "rgba(0,0,0,0.3)" }}
              >
                <a className="navbar-item has-text-light">Acerca</a>
                <a className="navbar-item has-text-light">
                  Trabaja con nosotros
                </a>
                <a className="navbar-item has-text-light">Contáctanos</a>
                <hr className="navbar-divider" />
                <a className="navbar-item has-text-light">Report an issue</a>
              </div>
            </div> */}
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <span
                  className="button is-light is-outlined"
                  onClick={onClickHandle}
                >
                  {idioma[2]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
