import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Loadable from "@loadable/component"
import anime from "animejs"

const Navbar = Loadable(() => import("../components/navbarAT"))

const Header = ({ siteTitle, animationCompleted, onClickModal }) => {
  useEffect(() => {
    animationCompleted &&
      anime({
        targets: `.navbar`,
        opacity: [0, 1],
        duration: 2500,
        easing: "linear",
      })
  }, [animationCompleted])

  return (
    <header>
      <div
        css={{
          margin: `0 auto`,
        }}
      >
        <Navbar
          animationCompleted={animationCompleted}
          onClickModal={onClickModal}
        />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
