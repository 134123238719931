import React, { Component } from "react"

if (typeof window !== `undefined`) {
  require("echarts-gl")
}

const echarts =
  typeof window !== "undefined" ? require("echarts/lib/echarts") : null

class EchartsTest extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      option: {
        grid3D: {
          boxWidth: 50,
          boxDepth: 50,
          boxHeight: 50,
          viewControl: {
            autoRotate: true,
          },
          axisLine: {
            lineStyle: { color: "#fff" },
          },
          axistPointer: {
            lineStyle: { color: "#fff" },
          },
        },
        xAxis3D: {
          data: this.data,
        },
        yAxis3D: {
          data: this.data,
        },
        zAxis3D: {
          data: this.data,
        },
        series: [
          {
            type: "scatter3D",
            symbolSize: 25,
            data: [
              [-1, -1, -1],
              [0, 0, 0],
              [1, 1, 1],
            ],
            itemStyle: {
              opacity: 1,
            },
          },
        ],
      },
    }
  }
  componentDidMount() {
    // var myChart = echarts.init(document.getElementById("main2"))
    const node = this.myRef.current
    var chart = echarts.init(node)
    chart.setOption(this.state.option)
  }
  render() {
    return (
      <div
        id="main2"
        className="container is-marginless is-paddingless"
        onWheel={(e) => e.stopPropagation()}
        onWheelCapture={(e) => e.stopPropagation()}
        ref={this.myRef}
        css={{
          height: "35vh",
          width: "100%",
        }}
      ></div>
    )
  }
}

export default EchartsTest
