import React from "react"
import { useTrail, animated, Transition } from "react-spring"
import { useInView } from "react-intersection-observer"
import Loadable from "@loadable/component"
import { isMobile } from "react-device-detect"
import Footer from "../components/footer"

const Plot1 = Loadable(() => import("../components/plots/apex/apexcharts"))
const Plot2 = Loadable(() => import("../components/plots/chartjs/lineChartHS"))
const Plot3 = Loadable(() => import("../components/plots/d3/d31"))
const Plot4 = Loadable(() => import("../components/plots/apex/apex3"))
const Plot5 = Loadable(() => import("../components/plots/echarts/ec2"))
const Plot6 = Loadable(() => import("../components/plots/echarts/ec3"))
const Plot7 = Loadable(() => import("../components/plots/echarts/ec4"))

const textoBase = {
  spanish: [
    ["Tus", "desafíos", "son", "únicos"],
    ["Te", "ofrecemos", "soluciones", "únicas"],
    ["Si", "quieres", "innovar"],
    ["Nosotros", "te", "ayudamos"],
    ["tu", "conocimiento", "experto"],
    ["apoyado con", "lo último en", "tecnología"],
    ["Conectividad", "palas eléctricas"],
    ["monitoreo", "en tiempo real"],
    ["análisis", "de datos"],
    ["aprendizaje", "de máquina"],
    ["reportes desempeño"],
    ["desarrollo", "de software", "a medida"],
    ["tus", "equipos", "de siempre"],
    ["bajo", "una nueva", "mirada"],
    ["una", "visión", "integral"],
    ["para", "todo", "tipo de", "industrias"],
    ["Los datos", "que te", "interesan"],
    ["en el", "momento", "que los", "necesitas"],
    ["Tú", "eliges el", "dispositivo"],
    ["Si", "tienes", "un", "proyecto"],
    ["o", "simplemente", "una idea"],
    ["contáctanos"],
    [""],
  ],
  english: [
    ["You", "have", "unique", "challenges"],
    ["We", "offer you", "unique", "solutions"],
    ["You", "want", "innovation"],
    ["We", "want", "to", "help you"],
    ["Your", "domain", "expertise"],
    ["State of", "the art", "technology"],
    ["Electric Shovels", "Connectivity"],
    ["Real time", "monitoring"],
    ["Data", "analytics"],
    ["Machine", "learning"],
    ["Performance reporting"],
    ["Custom", "made", "software"],
    ["Your", "faithful", "equipments"],
    ["within a", "completely", "new", "perspective"],
    ["Get", "the whole", "picture"],
    ["In any", "field", "or", "business"],
    ["The", "data", "you", "want"],
    ["The", "moment", "you", "need it"],
    ["Your", "device", "choice"],
    ["If you", "have", "a new", "project"],
    ["or maybe", "just", "an idea"],
    ["Get", "in", "touch"],
    [""],
  ],
  reportes: [
    {
      spanish: [
        "Los datos que quieres",
        "En el momento que los necesitas",
        "En el dispositivo que tu elijas",
      ],
    },
    {
      english: [
        "The data you want",
        "the moment you need it",
        "You choose the device",
      ],
    },
  ],
}

const texto = textoBase.english

const Texto = () => {
  return (
    <>
      {texto.map((textoRow, idx) => (
        <DivTexto key={`${textoRow}-${idx}`} item={textoRow} idx={idx} />
      ))}
      <Footer />
    </>
  )
}

const DivTexto = ({ item, idx }) => {
  const [refInView, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const trail = useTrail(item.length, {
    config: { mass: 1, tension: 140, friction: 12 },
    opacity: inView ? 1 : 0,
    x: inView ? 0 : 80,
    height: inView ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  const plot = (n) => {
    switch (n) {
      case 6:
        return (
          <Plot1
            colorLetras="#ffffff"
            fallback={<button className="is-loading" />}
          />
        )
      case 7:
        return (
          <Plot2
            colorLetras="#ffffff"
            fallback={<button className="is-loading" />}
          />
        )
      case 8:
        return <Plot3 fallback={<button className="is-loading" />} />
      case 10:
        return (
          <div css={{ height: "50%", widht: "100%" }}>
            <Plot4 fallback={<button className="is-loading" />} />
          </div>
        )
      case 12:
        return <Plot5 fallback={<button className="is-loading" />} />
      case 13:
        return <Plot6 fallback={<button className="is-loading" />} />
      case 14:
        return <Plot7 fallback={<button className="is-loading" />} />
      default:
        return
    }
  }

  if (idx === 22) return <div css={{ height: "4000vh" }} />

  return (
    <>
      <div className="trails-main grid">
        <div ref={refInView}>
          {inView && (
            <div
              className="container is-medium notification"
              style={{
                width: "80%",
                display: "relative",
                margin: "auto 0 auto",
                padding: "20 auto 20",
                background: "rgba(0,0,0,0.5)",
              }}
            >
              {trail.map(({ x, height, ...rest }, index) => (
                <div key={index}>
                  {isMobile ? (
                    <Transition
                      items={idx > 7 || idx < 20 ? true : false}
                      from={{ opacity: 0 }}
                      enter={{ opacity: 1 }}
                      leave={{ opacity: 0 }}
                    >
                      {(props) => (
                        <animated.div style={props}>
                          {item[index].split(" ").map((w, i) => (
                            <div className="trails-text" key={i}>
                              {w}
                            </div>
                          ))}
                        </animated.div>
                      )}
                    </Transition>
                  ) : (
                    <animated.div
                      className="trails-text"
                      style={{
                        ...rest,
                        transform: x.to((x) => `translate3d(0,${x}px,0)`),
                      }}
                    >
                      <div style={{ height }}>{item[index]}</div>
                    </animated.div>
                  )}
                </div>
              ))}
              {!isMobile && (
                <Transition
                  items={idx > 7 || idx < 20 ? true : false}
                  from={{ opacity: 0 }}
                  enter={{ opacity: 1 }}
                  leave={{ opacity: 0 }}
                >
                  {(props) => (
                    <animated.div style={props}>{plot(idx)}</animated.div>
                  )}
                </Transition>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Texto
