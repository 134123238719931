import React, { useState } from "react"
import "../components/styles/mystyles.scss"
import SEO from "../components/seo"
import TopBarProgress from "react-topbar-progress-indicator"
import Loadable from "@loadable/component"
import Texto from "../components/texto"
import Layout from "../components/layout"
import LogoAT from "../components/loaderAT"
import { isMobile } from "react-device-detect"

const ScrollLock = Loadable(() => import("../components/scroll-lock"))
const ContactModal = Loadable(() => import("../components/contact-modal"))

const Flecha = Loadable(() => import("../components/flecha"))
const ThreeCanvas = Loadable(
  () => import("../components/threejs/three-canvas"),
  { ssr: false }
)

const atPoint = {
  en: ["Scroll: Zoom", "Left Click: Swirl", "Right Click: Panning"],
  enM: ["Pinch: Zoom", "1 finger: Swirl", "2 fingers: Panning"],
  sp: "Movilidad liberada",
  spM: "Movilidad liberada",
}
const leng = isMobile ? atPoint.enM : atPoint.en

const IndexPage = () => {
  const [where, setWhere] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [animationCompleted, setAnimationCompleted] = useState(false)
  const [finished, setFinished] = useState(null)

  const handleAnimationCompleted = (e) => {
    // console.log("e from index: ", e)
    setAnimationCompleted(e)
  }

  const onClickModal = () => {
    setShowModal(!showModal)
  }

  TopBarProgress.config({
    barColors: {
      0: "#fff",
      "1.0": "#fff",
    },
    shadowBlur: 5,
  })

  ThreeCanvas.load().then(() => {
    setFinished(true)
  })

  const instruccion = () => (
    <h2 className="subtitle">
      {leng.map((text) => (
        <p>{text}</p>
      ))}
    </h2>
  )

  return (
    <Layout
      onClickModal={onClickModal}
      showModal={showModal}
      animationCompleted={animationCompleted}
      cbLogo={handleAnimationCompleted}
      finished={finished}
      pag1
    >
      {/* Modificar antes de publicar */}
      <SEO title="Home" />
      {showModal && (
        <ContactModal showModal={showModal} onClickModal={onClickModal} />
      )}
      {/* A little help for the Netlify post-processing bots */}
      <form
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input type="text" name="name" />
        <input type="text" name="empresa" />
        <input type="email" name="email" />
        <textarea name="mensaje"></textarea>
      </form>
      <div className="bg">
        <ThreeCanvas fallback={<LogoAT />} where={(e) => setWhere(e)} />
      </div>
      {!animationCompleted ? (
        <ScrollLock>
          <div className="bg-article" />
          <TopBarProgress />
        </ScrollLock>
      ) : (
        <>
          <article className="article" />
          <article className="article">
            <Texto />
          </article>
        </>
      )}
      <div
        css={{
          position: "fixed",
          top: "75vh",
          transform: "translateX(-50%)",
          left: "50%",
        }}
      >
        {!where && (
          <div
            className="box"
            css={{
              opacity: 0.7,
              background: "black",
            }}
          >
            <Flecha />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage
