import React, { useState } from "react"
import "../components/styles/mystyles.scss"
import Loadable from "@loadable/component"
import { Global, css } from "@emotion/react"
import Layout from "../components/layout"
import Footer from "../components/footer"

const ContactModal = Loadable(() => import("../components/contact-modal"))

const lenguaje = {
  en: [
    "Message Sent Succesfully",
    "Thanks for your message, we will be back at you as soon as possible.",
  ],
  sp: ["Mensaje Enviado", "Gracias por su mensaje, lo contáctaremos en breve."],
}

const idioma = lenguaje.en

export default function Gracias() {
  const [showModal, setShowModal] = useState(false)
  const onClickModal = () => {
    setShowModal(!showModal)
  }
  return (
    <Layout animationCompleted={true} onClickModal={onClickModal}>
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
        `}
      />
      <section className="hero is-fullheight is-dark is-bold">
        <div className="hero-head" />
        <div
          className="hero-body"
          css={{ display: "flex", alignItems: "center" }}
        >
          <div className="container">
            <h1 className="title">{idioma[0]}</h1>
            <h2 className="subtitle">{idioma[1]}</h2>
          </div>
        </div>
        <div className="hero-foot">
          <Footer />
        </div>
      </section>
      {showModal && (
        <ContactModal showModal={showModal} onClickModal={onClickModal} />
      )}
    </Layout>
  )
}
