import React, {
  useState,
  Suspense,
  useRef,
  useEffect,
  useCallback,
} from "react"
import { a } from "@react-spring/three"
import inRange from "lodash/inRange"
import useYScroll from "../useYScroll"
import { Canvas, useThree, useFrame, extend } from "@react-three/fiber"
import { isMobile } from "react-device-detect"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Model from "../cargador"
import Model2 from "../modelR3F"

extend({ OrbitControls })

const ThreeCanvas = ({ where }) => {
  const [y] = useYScroll([-100, 50000], {
    domTarget: window,
    event: { passive: "true" },
  })
  const [is30000, setIs30000] = useState(false)
  const [is45000, setIs45000] = useState(false)

  const Controls = (props) => {
    const ref = useRef(null)
    const { camera, gl } = useThree()
    useFrame(() => ref.current && ref.current.update())
    return <orbitControls ref={ref} args={[camera, gl.domElement]} {...props} />
  }
  const handleRotationX = (y) => {
    if (inRange(y, -1000, 250)) {
      return Math.sin(y / 250 - 1) * (Math.PI / 2)
    } else return 0
  }
  const handleRotationY = (y) => {
    if (y >= 44710) return 12.6
    else if (y >= 28000) return y * 0.0005 * Math.sin(y / 28000 - 1)
    else if (y >= 27000) return 0
    else if (y >= 15702) {
      return (y / 5600) * Math.sin((1 / 8616) * (y - 15702)) + 7.86
    } else if (y > 250) {
      return y * 0.0005
    } else {
      return y * 0.0005 + Math.sin(y / 250 - 1)
    }
  }

  useEffect(() => {
    if (y > 45000 && where === false) {
      where(true)
    } else if (y > 30000 && where === false) {
      where(true)
    } else {
      if (where === true) {
        where(false)
      }
    }
  }, [is30000, is45000])

  const handleX = useCallback(
    (y) => {
      if (y > 45000) {
        if (!is45000) setIs45000(true)
      } else if (y > 30000) {
        if (!is30000) setIs30000(true)
      }
      return 0
    },
    [is30000, is45000]
  )

  const handleY = (y) => {
    if (y >= 53000) return y * -0.002 * Math.sin(y / 43000 - 1) + 36000 * -0.001
    else if (y >= 39000)
      return y * -0.002 * Math.sin(-y / 39000 + 1) + 34100 * -0.001
    else if (y >= 30200) return -34.1
    else if (y >= 27000)
      return y * -0.002 * Math.sin(y / 27000 - 1) + 27000 * -0.001
    else if (y > 0) return y * -0.001
    else return 0
  }
  const handleZ = (y) => {
    if (y >= 44710) return -10.85
    else if (y >= 41000)
      return 60 * Math.sin((2 * (y * Math.PI)) / 41000 - Math.PI) + 21.36
    else if (y >= 30200)
      return 6 * Math.sin((y * Math.PI) / 30200 - Math.PI) + 15.95
    else if (y > 27000) return (y - 27000) * 0.005
    else if (y >= 250)
      return (
        5 * (Math.sin(Math.PI / y) - (Math.PI / y) * Math.cos(Math.PI / y)) - 5
      )
    else return -5
  }
  const handleSize = (y) => {
    if (y > 250) return 0.03
    else {
      return 0.03 - Math.sin(y / 250 - 1)
    }
  }
  const handleYPlane = (y) => {
    if (y > 250) {
      return -3
    } else {
      return 15 * Math.sin(y / 250 - 1)
    }
  }

  const renderOnMobile1 = (
    <Suspense fallback={null}>
      <Model
        page="ModeloAT2"
        url="/heli_draco.glb"
        position={[5, 10, 16]}
        color="forestgreen"
        rotation={[0, (4 * Math.PI) / 6, 0]}
        dispose={null}
      />
    </Suspense>
  )

  const renderOnMobile2 = (
    <>
      <Suspense fallback={null}>
        <Model
          url="/60903_draco.glb"
          position={[0, -3, -8]}
          color="lightseagreen"
          rotation={[0, (6 * Math.PI) / 6, 0]}
          dispose={null}
        />
      </Suspense>
      <Suspense fallback={null}>
        <Model2
          url="/4957_draco.glb"
          position={[14, -3, 0]}
          color="mediumseagreen"
          rotation={[0, -(8 * Math.PI) / 6, 0]}
        />
      </Suspense>
    </>
  )

  return (
    <Canvas
      className="bgCanvas"
      camera={{ position: [0, -2, 24], far: 50 }}
      // invalidateFrameloop={is30000 ? false : true}
      dpr={1}
      mode="concurrent"
    >
      <a.group
        rotation-x={y.to(handleRotationX)}
        rotation-y={y.to(handleRotationY)}
        position-x={y.to(handleX)}
        position-y={y.to(handleY)}
        position-z={y.to(handleZ)}
      >
        {/*
        {is45000 &&
          (
          <Controls
            screenSpacePanning
            enableDamping
            rotateSpeed={0.5}
            zoomSpeed={0.5}
            dampingFactor={0.5}
            maxDistance={30} //16
            minDistance={0}
            minPolarAngle={1}
            maxPolarAngle={1.85}
          />
        )} */}
        {!isMobile && renderOnMobile1}
        <Suspense fallback={null}>
          <Model
            url="/auto_draco.glb"
            position={[-2.5, -3, 22]}
            color="forestgreen"
            rotation={[0, -Math.PI / 6, 0]}
            dispose={null}
          />
        </Suspense>
        <Suspense fallback={null}>
          <Model
            url="/789c_draco.glb"
            position={[-7, -3, 17]}
            color="mediumseagreen"
            rotation={[0, -Math.PI / 4, 0]}
            dispose={null}
          />
        </Suspense>
        <Suspense fallback={null}>
          <Model
            url="/perfo5_draco.glb"
            position={[-15, -3, 8]}
            color="darkgreen"
            rotation={[0, -Math.PI / 1.7, 0]}
            dispose={null}
          />
        </Suspense>
        <Suspense fallback={null}>
          <Model
            url="/60503_draco.glb"
            position={[-11, -3, -2]}
            color="forestgreen"
            rotation={[0, (7.5 * Math.PI) / 6, 0]}
            dispose={null}
          />
        </Suspense>
        <Suspense fallback={null}>
          <Model
            url="/WT16_anim_draco.glb"
            position={[0, -3, 4]}
            color="#66a103"
            active={is30000}
            dispose={null}
          />
        </Suspense>
        {!isMobile && renderOnMobile2}
        <a.points
          rotation={[-Math.PI / 2, 0, 0]}
          position-x="0"
          position-y={y.to(handleYPlane)}
          position-z="0"
        >
          <planeBufferGeometry attach="geometry" args={[75, 75, 128, 128]} />
          <a.pointsMaterial
            attach="material"
            color="#4c9a2a"
            size={y.to(handleSize)}
          />
        </a.points>
      </a.group>
      <fogExp2 attach="fog" args={[`#173030`, 0.07]} />
    </Canvas>
  )
}

export default ThreeCanvas
