import React from "react"
import ContactForm from "./contact-form"

export default function ContactModal({ showModal, onClickModal }) {
  return (
    <div className={`modal ${showModal ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="container" style={{ padding: 45 }}>
          <ContactForm onClickModal={onClickModal} />
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => {
          onClickModal()
        }}
      />
    </div>
  )
}
