import React, { Component } from "react"

if (typeof window !== `undefined`) {
  require("echarts-gl")
}

const echarts =
  typeof window !== "undefined" ? require("echarts/lib/echarts") : null

const SimplexNoise =
  typeof window !== "undefined" ? require("simplex-noise") : null

class EchartsTest extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      option: {
        tooltip: {},
        visualMap: {
          show: false,
          min: 2,
          max: 6,
          inRange: {
            symbolSize: [0.5, 15],
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
            colorAlpha: [0.2, 1],
          },
        },
        xAxis3D: {
          type: "value",
        },
        yAxis3D: {
          type: "value",
        },
        zAxis3D: {
          type: "value",
        },
        grid3D: {
          boxWidth: 50,
          boxDepth: 50,
          boxHeight: 50,
          axisLine: {
            lineStyle: { color: "#fff" },
          },
          axisPointer: {
            lineStyle: { color: "#fff" },
          },
          viewControl: {
            autoRotate: true,
          },
        },
        series: [
          {
            type: "scatter3D",
            data: this.data,
          },
        ],
      },
    }
  }

  data = this.generateData()
  valMin = Infinity
  valMax = -Infinity

  generateData() {
    const noise = new SimplexNoise(Math.random)
    let data = []
    for (var i = 0; i <= 40; i++) {
      for (var j = 0; j <= 40; j++) {
        for (var k = 0; k <= 40; k++) {
          var value = noise.noise3D(i / 20, j / 20, k / 20)
          this.valMax = Math.max(this.valMax, value)
          this.valMin = Math.min(this.valMin, value)
          data.push([i, j, k, value * 2 + 4])
        }
      }
    }
    return data
  }

  componentDidMount() {
    // var chart = echarts.init(document.getElementById("main3"))
    const node = this.myRef.current
    var chart = echarts.init(node)
    chart.setOption(this.state.option)
  }
  render() {
    return (
      <div
        id="main3"
        className="container is-marginless is-paddingless"
        onWheel={(e) => e.stopPropagation()}
        onWheelCapture={(e) => e.stopPropagation()}
        ref={this.myRef}
        css={{
          height: "35vh",
          width: "100%",
        }}
      ></div>
    )
  }
}

export default EchartsTest
