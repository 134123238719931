import React, { Component } from "react"
// import ReactApexChart from "react-apexcharts"
import Loadable from "@loadable/component"
import dataSeries from "../../assets/irregular-data-series"

const ReactApexChart = Loadable(() => import("react-apexcharts"))

var ts2 = 1484418600000
var dates = []
var spikes = [5, -5, 3, -3, 8, -8]
for (var i = 0; i < 120; i++) {
  ts2 = ts2 + 86400000
  var innerArr = [ts2, dataSeries[1][i].value]
  dates.push(innerArr)
}

class LineChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        chart: {
          id: "apexcharts",
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        plotOptions: {
          line: {
            curve: "smooth",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "full",
        },
        colors: ["#cd5c5c"],
        title: {
          // text: "Stock Price Movement",
          align: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          min: 20000000,
          max: 250000000,
          labels: {
            style: {
              colors: this.props.colorLetras,
            },
            formatter: function (val) {
              return (val / 1000000).toFixed(0)
            },
          },
          title: {
            text: "Producción",
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              colors: this.props.colorLetras,
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0)
            },
          },
        },
      },
      series: [
        {
          name: "Flota Palas",
          data: dates,
        },
      ],
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height="300"
        />
      </div>
    )
  }
}

export default LineChart
