import React from "react"
import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import platformImage from "../components/threejs/assets/Sand.jpg"

export default function Piso(props) {
  const arenaImagen = platformImage
  const arenaMap = useLoader(TextureLoader, arenaImagen)
  return (
    <mesh {...props}>
      <cylinderGeometry args={[85, (170 / 3) * 0.5 + 0.2, 1, 85]} />
      <meshStandardMaterial envMap={arenaMap} map={arenaMap} />
    </mesh>
  )
}
