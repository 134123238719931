import React, { Suspense, useRef, useMemo, useState } from "react"
import Modelor3f from "../components/modelR3F"
import {
  sRGBEncoding,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
} from "three/src/constants"
import { Canvas, useFrame, useThree, extend } from "@react-three/fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Piso from "./piso"
import LogoAT from "../components/loaderAT"
import { Html } from "@react-three/drei"

import { CubeMap } from "./cubeMap"
import { isMobile } from "react-device-detect"

extend({ OrbitControls })

const Canvasr3f = ({ url }) => {
  const [esCamion, setEsCamion] = useState(null)

  const Controls = (props) => {
    const controlsRef = useRef()
    const { invalidate, camera, gl } = useThree()
    useFrame(() => controlsRef?.current.update())
    return (
      <orbitControls
        ref={controlsRef}
        args={[camera, gl.domElement]}
        {...props}
      />
    )
  }

  const Scene = (props) => {
    const cubeMap = useMemo(() => CubeMap(), [])
    const refScene = useRef(null)
    const { gl, scene, camera } = useThree()
    useFrame(() => {
      scene.background = cubeMap
      scene.environment = cubeMap
      gl.toneMapping = ACESFilmicToneMapping
      gl.toneMappingExposure = 0.3
      gl.outputEncoding = sRGBEncoding
      gl.render(scene, camera)
    })
    return <scene ref={refScene} {...props} />
  }

  return (
    <Canvas
      mode="concurrent"
      dpr={1}
      camera={{
        position: url ? [15, 1, -15] : [-3, -5, 15],
        zoom: isMobile ? 0.5 : 1,
      }}
      invalidateFrameloop={true}
      shadows
    >
      <Scene />
      <hemisphereLight intensity={0.1} groundColor={"rgb(255, 255, 51)"} />
      <directionalLight
        color={"rgb(255, 234, 230)"}
        intensity={2}
        position={[-14.5, 1, 20]}
        shadow-camera-left={-100}
        shadow-camera-top={100}
        shadow-camera-right={100}
        shadow-camera-bottom={-100}
        shadow-camera-near={1}
        shadow-camera-far={130}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        castShadow
      />
      <Controls
        autoRotate={url ? false : true}
        enablePan={false}
        enableDamping
        autoRotateSpeed={1}
        rotateSpeed={0.07}
        zoomSpeed={0.07}
        dampingFactor={0.9}
        maxDistance={url ? 45 : 16} //16
        minDistance={url ? 38 : 14}
        minPolarAngle={url ? 1 : 1}
        maxPolarAngle={url ? 1.7 : 1.85}
      />
      <Suspense fallback={null}>
        <Modelor3f
          segunda
          dispose={null}
          position-y={-5}
          url={url ? url : "/4957_draco.glb"}
          camion={esCamion}
        />
      </Suspense>
      <Suspense fallback={null}>
        <Piso receiveShadow position-y={-5.5} />
      </Suspense>
      {url && (
        <Html fullscreen style={{ pointerEvents: "none" }}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: "15%",
              left: "5%",
            }}
          >
            <button
              type="button"
              onClick={() => {
                esCamion === "tolva" ? setEsCamion(null) : setEsCamion("tolva")
              }}
              css={{
                outline: "none",
                backgroundColor: esCamion === "tolva" ? "#444" : "white",
                textAlign: "center",
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 30,
                paddingRight: 30,
                cursor: "pointer",
                borderRadius: 5,
                color: esCamion === "tolva" ? "white" : "gray",
                fontWeight: "bold",
                fontSize: isMobile ? 16 : 18,
                width: isMobile ? 120 : 240,
                border: "none",
                pointerEvents: "auto",
                "&:hover": {
                  backgroundColor: "darkgray",
                  color: "white",
                },
                "&:active": {
                  backgroundColor: "#444",
                  color: "darkgray",
                },
              }}
            >
              TOLVA
            </button>
            <button
              type="button"
              onClick={() => {
                esCamion === "tren" ? setEsCamion(null) : setEsCamion("tren")
              }}
              css={{
                outline: "none",
                backgroundColor: esCamion === "tren" ? "#444" : "white",
                textAlign: "center",
                marginLeft: 16,
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 30,
                paddingRight: 30,
                cursor: "pointer",
                borderRadius: 5,
                color: esCamion === "tren" ? "white" : "gray",
                fontWeight: "bold",
                fontSize: isMobile ? 16 : 18,
                width: isMobile ? 140 : 240,
                border: "none",
                pointerEvents: "auto",
                "&:hover": {
                  backgroundColor: "darkgray",
                  color: "white",
                },
                "&:active": {
                  backgroundColor: "#444",
                  color: "darkgray",
                },
              }}
            >
              TREN DE POTENCIA
            </button>
          </div>
        </Html>
      )}
    </Canvas>
  )
}

export default Canvasr3f
