import React, { useState } from "react"
import SEO from "../components/seo"
import "../components/styles/mystyles.scss"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import Footer from "../components/footer"
import { Global, css } from "@emotion/react"

const ContactModal = Loadable(() => import("../components/contact-modal"))

export default function Empresas() {
  const [showModal, setShowModal] = useState(false)

  const onClickModal = () => {
    setShowModal(!showModal)
  }

  return (
    <Layout animationCompleted={true} onClickModal={onClickModal}>
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
        `}
      />
      <SEO title="Dashboard" />
      {showModal && (
        <ContactModal showModal={showModal} onClickModal={onClickModal} />
      )}
      <section className="hero is-fullheight is-dark is-bold">
        <div className="hero-head" />
        <div
          className="hero-body"
          css={{ display: "flex", alignItems: "center" }}
        >
          <div className="container">
            <h1 className="title">Empresas</h1>
            <h2 className="subtitle">
              La ayuda que necesitas es la intranet más económica del mercado.
              <br /> Contáctanos.
            </h2>
          </div>
        </div>
        <div className="hero-foot">
          <Footer />
        </div>
      </section>
    </Layout>
  )
}
